.react-resizable {
    position: relative;
}
.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
}
.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
}
.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
}
.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
}
.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
}
.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
}
.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
}
.react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
}

/* example.css */
.layoutRoot {
    display: flex;
    background: #eee;
    margin-bottom: 20px;
    flex-wrap: wrap;
}
.absoluteLayout {
    height: 600px;
    position: relative;
    justify-content: center;
    align-items: center;
}
.scaledLayout {
    width: 125%;
    left: -12.5vw;
    transform: scale(0.75);
    margin-top: -7.5vh;
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ccc;
    border: 1px solid black;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    margin: 20px;
}
.box .text {
    text-align: center;
}

.hover-handles .react-resizable-handle {
    display: none;
}
.hover-handles:hover .react-resizable-handle {
    display: block;
}
.absolutely-positioned {
    position: absolute !important;
}
.left-aligned {
    left: 0;
}
.right-aligned {
    right: 0;
}
.top-aligned {
    top: 0;
}
.bottom-aligned {
    bottom: 0;
}

.custom-box {
    overflow: visible;
}
.custom-handle {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #1153aa;
    opacity: 0.75;
    border-radius: 4px;
}
.custom-handle-sw {
    bottom: -4px;
    left: -4px;
    cursor: sw-resize;
}
.custom-handle-se {
    bottom: -4px;
    right: -4px;
    cursor: se-resize;
}
.custom-handle-nw {
    top: -4px;
    left: -4px;
    cursor: nw-resize;
}
.custom-handle-ne {
    top: -4px;
    right: -4px;
    cursor: ne-resize;
}
.custom-handle-w,
.custom-handle-e {
    top: 50%;
    margin-top: -4px;
    cursor: ew-resize;
}
.custom-handle-w {
    left: -4px;
}
.custom-handle-e {
    right: -4px;
}
.custom-handle-n,
.custom-handle-s {
    left: 50%;
    margin-left: -4px;
    cursor: ns-resize;
}
.custom-handle-n {
    top: -4px;
}
.custom-handle-s {
    bottom: -4px;
}
.custom-resize-handle-component {
    background-color: red;
}