// Copyright (C) 2022-2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

@import 'pages/user/label/annotation/image/base.scss';

.coco-brush-tools-toolbox {
    cursor: move;
    position: fixed;
    margin: $grid-unit-size;
    padding: 0 20px;
    border-radius: 50px;
    border: 2px solid var(--coco-primary-200);
    background: var(--coco-default-white);
    //background: $background-color-2;
    display: flex;
    align-items: center;
    z-index: 100;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.13);;

    > hr {
        width: 1px;
        height: $grid-unit-size * 4;
        background: $border-color-1;
    }

    > * {
        margin-right: $grid-unit-size;
    }

    > button {
        font-size: 24px;
        border: 0;
        background: transparent;
        color: var(--coco-primary-400);

        > span.anticon {
            font-size: inherit;
        }
    }

    .input-step.light{
        input {
            color: var(--coco-gray-700);
        }
        .coco-brush-tools-size-control {
            background-color: var(--coco-primary-400);
            color: var(--coco-default-white);
            padding: 4px 8px !important;
            font-size: 14px;
            width: 100%
        }
    }
    .coco-brush-tools-brush,
    .coco-brush-tools-eraser {
        svg {
            width: 24px;
            height: 25px;
        }
    }

    .coco-brush-tools-draggable-area {
        display: flex;
        font-size: 20px;

        svg {
            width: 24px;
            height: 25px;
        }
    }

    .coco-brush-tools-active-tool {
        background: $header-color;
    }
}
