/*@font-face {*/
/*  font-family: "Inconsolota";*/
/*  src: local("inconsolota"),*/
/*    url("components/common/text-annotator/fonts/inconsolata/Inconsolata.ttf") format("truetype");*/
/*}*/

@font-face {
  font-family: Pretendard, Poppins!important;
}

.rta-annotator-container {
  display: flex;
  flex-direction: column;
}

.rta-legend-container {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.rta-legend-option-container {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.rta-legend-option-input {
  margin: 0px;
}

.rta-legend-option-color-line {
  height: 5px;
  width: 20px;
  margin: 5px;
}

.rta-legend-option-visibility-button {
  display: flex;
  background-color: transparent;
  border: none;
}

.rta-default-contextual-menu-container {
  max-width: 200px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.rta-default-contextual-menu-line {
  margin-top: 5px;
}

.rta-default-contextual-menu-icon-container {
  margin-right: 10px;
}

.rta-text-container {
  position: relative;
  top: 0px;
  left: 0px;
  font-family: Pretendard;
  /*cursor: url("assets/images/dragCursor-24.png"), auto;*/
  z-index: 10;
  white-space: pre-wrap;
  word-break: break-all;
  tab-size: 1; /* Because of the monospace font, a tab size different from 1 means we would need to shift all annotation values for display and unshift them when saving. */
}

.rta-svg {
  position: absolute;
  z-index: 1;
}

.rta-contextual-menu {
  background-color: #fff;
  border-radius: 2px;
  padding: 5px 0 5px 0;
  margin: 0;
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  z-index: 30;
}
