// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2022 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

@import 'pages/user/label/annotation/image/base.scss';

.label-object-menu-div{
    .card-footer {
        padding: 12px 24px;
        height: 59px;
    }
}
.cvat-objects-appearance-collapse.ant-collapse {
    width: 100%;
    border-radius: 0;

    > .ant-collapse-item {
        border: none;

        > .ant-collapse-header {
            padding-top: 2.5px;
            padding-bottom: 2.5px;
            background: $header-color;
            border-radius: 0;
            height: 25px;

            .ant-collapse-arrow {
                padding: 0;
                top: $grid-unit-size;
            }
        }

        > .ant-collapse-content {
            background: $background-color-2;
            border-bottom: none;
            padding-bottom: $grid-unit-size * 3;

            > .ant-collapse-content-box {
                padding: 10px;
                padding-bottom: 0;
            }
        }
    }
}

.cvat-object-sidebar-icon {
    fill: $objects-bar-icons-color;
    color: $objects-bar-icons-color;
    font-size: 15px;

    &:hover {
        transform: scale(1.1);
    }
}

.listContainer{
    padding-top: 0;
    height: calc(100vh - (70px + 59px));

    .tab-content{
        .card-body{
            padding: 24px 24px 0;
        }
    }
    .tab-header {
        border-bottom : 1px solid var(--coco-gray-100) !important;
        padding: 0 0 12px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }
}

.cvat-objects-sidebar-tabs.ant-tabs.ant-tabs-card {
    background: $header-color;
    box-sizing: border-box;
    border: none;

    .ant-tabs-nav {
        &::before {
            content: none;
        }

        border: none;
        margin-bottom: 0;
        padding-top: 25px;

        .ant-tabs-tab {
            background: $transparent-color;
            border: none;

            &:nth-child(1) {
                margin-left: 5px;
            }
        }

        .ant-tabs-tab.ant-tabs-tab-active {
            background: $objects-bar-tabs-color;
        }
    }
}

.cvat-objects-sidebar-issues-list-header {
    background: $objects-bar-tabs-color;
    padding: $grid-unit-size;
    height: $grid-unit-size * 4;
    box-sizing: border-box;

    > div > div {
        > span[role='img'] {
            font-size: 16px;
            color: $objects-bar-icons-color;

            &:hover {
                transform: scale(1.1);
                opacity: 0.8;
            }

            &:active {
                transform: scale(1);
                opacity: 0.7;
            }
        }
    }
}

.cvat-objects-sidebar-issues-list {
    background-color: $background-color-2;
    height: calc(100% - 32px);
    overflow-y: auto;
    overflow-x: hidden;
}

.cvat-objects-sidebar-issue-item {
    width: 100%;
    margin: 1px;
    padding: 2px;

    &:hover {
        padding: 0;

        > .ant-alert {
            border-width: 3px;
        }
    }

    > .ant-alert.ant-alert-with-description {
        padding: $grid-unit-size $grid-unit-size $grid-unit-size $grid-unit-size * 8;
    }
}

.cvat-objects-sidebar-states-header {
    background: $objects-bar-tabs-color;
    padding: 5px;

    > div:nth-child(1) {
        margin-top: $grid-unit-size;

        > div {
            text-align: center;
            margin: 0 2px;

            > span[role='img'] {
                @extend .cvat-object-sidebar-icon;
            }

            &:last-child {
                text-align: right;

                > .cvat-objects-sidebar-ordering-selector {
                    margin-left: $grid-unit-size;
                    width: 60%;
                }
            }
        }
    }
}

.coco-objects-sidebar-states-list {
    background-color: $background-color-2;
    height: calc(100% - 50px);
    overflow-y: auto;
}

.cvat-objects-sidebar-z-layer-mark {
    text-align: center;
}

.cvat-objects-sidebar-state-item.cvat-objects-sidebar-state-active-item {
    border-top: 2px solid $object-item-border-color;
    border-right: 2px solid $object-item-border-color;
    border-bottom: 2px solid $object-item-border-color;
    padding: 3px 1px 1px 3px;
}

.cvat-objects-sidebar-state-item-color,
.cvat-objects-sidebar-label-item-color {
    border: 1px solid $object-item-border-color;
    width: 7px;
    opacity: 1;

    &:hover {
        opacity: 0.7;
    }
}

.cvat-objects-sidebar-state-item {
    width: 100%;
    padding: 5px 3px 3px 3px;
    opacity: 1;

    > div:nth-child(1) {
        > div:nth-child(1) {
            line-height: 12px;
        }

        > div:nth-child(3) > span {
            @extend .cvat-object-sidebar-icon;

            font-size: 25px;
        }

        > div:nth-child(2) > .ant-select {
            width: 100%;
        }
    }

    > div:nth-child(2) {
        > div > div {
            margin-top: 5px;
        }

        span[role='img'] {
            @extend .cvat-object-sidebar-icon;
        }
    }
}

.cvat-objects-sidebar-state-item-collapse,
.coco-objects-sidebar-state-item-elements-collapse {
    border: unset;
    background: unset;
    width: 100%;

    > .ant-collapse-item {
        background: inherit;
        border: none;

        > .ant-collapse-header {
            align-items: baseline;
            padding-top: 2px;
            padding-bottom: 2px;

            .ant-collapse-arrow {
                padding: 0;
                top: $grid-unit-size;
            }
        }

        > .ant-collapse-content {
            > .ant-collapse-content-box {
                padding: 0;
            }

            border: unset;
            background: inherit;
        }
    }
}

.cvat-object-item-attribute-wrapper {
    margin-top: 5px;
}

.cvat-object-item-select-attribute {
    width: 100%;
}

.cvat-object-item-number-attribute {
    width: 100%;
}

.cvat-object-item-text-attribute {
    width: 100%;
}

.cvat-object-item-radio-attribute {
    border: 1px double $border-color-hover;
    border-radius: 7px 7px 7px 7px;

    > legend {
        text-align: center;
        width: unset;
        text-overflow: ellipsis;
        max-width: 80%;
        overflow: hidden;
        max-height: 1.2em;
        font-size: 1em;
        margin: 0;

        > span {
            padding: 0 10px;
        }
    }

    > .ant-radio-group {
        display: grid;
        padding: 1px 5px 1px 5px;
    }
}

.coco-objects-sidebar-labels-list {
    background-color: $background-color-2;
    height: 100%;
    overflow-y: auto;
}

.cvat-objects-sidebar-label-active-item {
    background: $active-label-background-color;
    border-top: 2px solid $object-item-border-color;
    border-right: 2px solid $object-item-border-color;
    border-bottom: 2px solid $object-item-border-color;
    padding: 3px 1px 1px 3px;
}

.cvat-objects-sidebar-label-item {
    height: 2.5em;
    border-bottom: 1px solid $border-color-1;
    padding: 5px 3px 3px 3px;

    span {
        @extend .cvat-object-sidebar-icon;
    }

    > div:nth-child(2) {
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 1.5em;
        font-size: 1em;
    }

    &:hover {
        @extend .cvat-objects-sidebar-label-active-item;
    }
}

.cvat-label-item-color {
    background: rgb(25, 184, 14);
    height: 80%;
    width: 90%;
    border-radius: $grid-unit-size * 0.5;
}

.cvat-label-item-setup-shortcut-button {
    border-color: $objects-bar-icons-color;
}

.cvat-label-item-setup-shortcut-popover {
    margin-top: -$grid-unit-size;
    margin-bottom: -$grid-unit-size;

    > div {
        padding-top: $grid-unit-size;
        padding-bottom: $grid-unit-size;

        > div {
            display: flex;
            justify-content: center;

            > button {
                width: $grid-unit-size * 15;
                overflow-x: hidden;

                span:first-child {
                    margin-right: $grid-unit-size;
                }
            }
        }
    }
}

.cvat-objects-appearance-content {
    > div {
        width: 100%;

        > label {
            text-align: center;
            width: 33%;
        }
    }

    .ant-checkbox-wrapper {
        margin-left: 0;
    }
}

.cvat-context-menu-item.ant-menu-item {
    &:hover {
        background: $hover-menu-color;
    }
}

.cvat-object-item-menu {
    > li {
        padding: 0;

        > button {
            padding: 5px 32px;
            color: $text-color;
            width: 100%;
            height: 100%;
            text-align: left;
        }
    }
}

.cvat-label-color-picker .sketch-picker {
    box-shadow: unset !important;
}

.cvat-states-ordering-selector {
    :first-child {
        margin-right: $grid-unit-size;
    }
}

.cvat-objects-sidebar-label-item-disabled {
    opacity: 0.5;
}

.cvat-workspace-settings-approx-poly-threshold {
    .ant-slider-track {
        background: linear-gradient(90deg, #1890ff 0%, #61c200 100%);
    }
}

.cvat-approx-poly-threshold-wrapper {
    @extend .cvat-workspace-settings-approx-poly-threshold;

    width: $grid-unit-size * 32;
    position: absolute;
    background: $background-color-2;
    top: 8px;
    left: 50%;
    border-radius: 6px;
    border: 1px solid $border-color-3;
    z-index: 100;
    padding: $grid-unit-size * 0.5 $grid-unit-size * 2 $grid-unit-size * 0.5 $grid-unit-size * 0.5;

    .ant-slider-mark {
        position: static;
        margin-top: 4px;
        pointer-events: none;
    }
}

.cvat-objects-sidebar-state-item-elements {
    padding: $grid-unit-size * 0.5;
    border: 1px solid $object-item-border-color;
    border-bottom: 0;
    padding-top: 1px;
    padding-left: 1px;
    padding-right: 1px;
    padding-bottom: 2px;

    &:last-child {
        border-bottom: 1px solid $object-item-border-color;
    }
}

.cvat-objects-sidebar-state-item-elements.cvat-objects-sidebar-state-active-element {
    border: 2px dashed $object-item-border-color;
    padding: 0;
}
