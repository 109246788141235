.coco-canvas-context-menu{
  position: fixed;

  .card-header{
    color: var(--coco-gray-700);
    .i-coco-20 {
      background: var(--coco-gray-700);
    }
    .coco-object-popover-header{
      font-size: 16px;
      font-weight: 700;
    }
  }

  .card-body {
    background: #FFFFFF;
    border-radius: 0 0 0.25rem 0.25rem;

    h6, .coco-object-popover-attribute-title {
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 0.5rem;
      margin-bottom: 0;
    }

    .coco-chip-options-icon {
      font-size: 20px;
      color: var(--coco-gray-400);
    }

  }

  .validation-error input{
    //border: 0 !important;
  }
  .validation-error-message {
    margin-bottom: 0;
    padding-top: 1px;
    padding-left: 1px;
  }
}

.btn-coco-outline-1 {
  .btn-check:checked+ & {
    color: var(--coco-default-white) !important;
  }

  .btn-check:disabled + & {
    color: var(--coco-gray-200) !important;
    background-color: #F0F0F0;
    border-color: transparent;
  }

  &:hover {
    border-color: transparent;
    background-color: var(--coco-primary-50);
  }

  .btn-check:checked:disabled + & {
    color: var(--coco-default-white) !important;
    background-color: var(--coco-gray-200);
    border-color: transparent;
  }
}

.coco-object-popover-attribute{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.coco-chip-options-container {
  width: 200px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.17);
  border-radius: 7px;
  .title{
    font-weight: 500;
    font-size: 12px;
    padding: 16px 16px 8px;
    height: 38px;
    line-height: 14px;
  }
  .content {
    display: flex;
    gap: 0 8px;
    flex-wrap: wrap;
    padding: 0 16px 16px;
    .form-check{
      padding: 0;
    }
    .form-label{
      font-size: 12px;
      line-height: 14px;
      height: 22px;
      padding: 4px 6px;
    }
  }
}

// cursor
.cursor{
  cursor: move;
}
