.waveform {
  height: calc(100% - (58px + 17px));
  //height: 100%;
  div{
    &::part(scroll) {
      //scrollbar-gutter: stable both-edges;
      overflow-x: scroll !important;
      //&::-webkit-scrollbar {
      //  width: 7px;
      //}
      //&::-webkit-scrollbar-thumb {
      //  background-color: rgba(0,0,0,0.4);
      //  border-radius: 10rem;
      //  border: 1px solid #fff;
      //}
      //
      //&::-webkit-scrollbar-track-piece:start {
      //  background: transparent;
      //}
      //&::-webkit-scrollbar-track-piece:end {
      //  background: transparent;
      //}
    }
    height: 100%;
  }
}
.wave-tools-wrapper{
  height: fit-content;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.audio-control-bar {
  display: flex;  // inline-flex
  padding: 17px 32px;
  height: 58px;
  align-items: center;
  justify-content: space-between;
  //gap: 516px;
  background: var(--coco-default-white);
  width: inherit;
  line-height: 24px;

  i{
    cursor: pointer;
  }
  .time {
    width: 200px;
    justify-content: left !important;
    line-height: 20px;
    .MM-ss{
      width: 43px;
    }
    .HH-MM-ss{
      width: 66px;
    }
  }
  .control{
    width: 200px;
    justify-content: right !important;
  }
  .group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    color: var(--coco-gray-700);

    .dropdown-menu-center {
      right: auto !important;
      left: 50% !important;
      transform: translate(-50%, -24px) !important;
    }

    .dropdown-menu {
      min-width: 82px;
      transform: translateX(50%);
      background-color: var(--coco-default-black-op-70);
      color: var(--coco-default-white);
      font-size: 12px;
      padding: 12px 16px 16px 16px;

      .dropdown-header {
        color: var(--coco-default-white);
        padding: 0 0 6px 0;
        line-height: 14px;
      }
      .dropdown-divider {
        margin: 0 0 6px 0;
      }
      .dropdown-item {
        color: var(--coco-default-white);
        padding: 5px 2px;
        line-height: 14px;
      }
      &.volume {
        min-width: 60px;
      }
    }
  }

  .rangeslider-vertical .rangeslider__labels{
    left: 0 !important;
  }
}
