
//
//  _sweetalert2.scss
//

.swal2-container {
  z-index: 1080!important;
  .swal2-title{
    padding: 0;
    font-size: 20px;
    color: var(--coco-gray-700);
    font-weight: 600;
  }  
}

.swal2-popup{
  padding: 32px 24px!important;
  border-radius: $modal-content-border-radius;
  background-color: $modal-content-bg;
  color: var(--#{$variable-prefix}body-color);

  .swal2-title{
    color: var(--coco-gray-700);
  }

  .swal2-html-container{
    color: var(--coco-gray-700);
  }
}

.swal2-footer{
  border-top: 1px solid var(--#{$variable-prefix}border-color);
  color: var(--#{$variable-prefix}body-color);
}

.swal2-html-container{
  font-size: 16px!important;
  margin: 8px 0 4px 0 !important;
  font-weight: 500!important;
}

.swal2-icon{
  width: 80px!important;
  height: 80px!important;
  border-width: 8px!important;
  margin-top: 8px!important;
  margin-bottom: 22px!important;
  .swal2-icon-content{
    font-size: 80px!important;
    font-width: 500;
  }
  &.swal2-question{
    border-color: var(--coco-secondary-400)!important;
    color: var(--coco-secondary-400)!important;
  }
  &.swal2-success {
    border-color: var(--coco-primary-a-400)!important;
    color: var(--coco-primary-a-400)!important;
    border-width: 8px!important;
    [class^=swal2-success-line]{
      background-color: var(--coco-primary-a-400)!important;
    }

    [class^=swal2-success-line]{
      height: 0.5em!important;
      //&[class$=tip]{
      //  width: 1.7em!important;
      //}
    }

    .swal2-success-ring{
      border-color: var(--coco-primary-a-400)!important;
      border-width: 8px!important;
      top: -0.5em!important;
      left: -0.48em!important;
    }
  }
  &.swal2-info{
    border-color: var(--coco-secondary-a-200)!important;
    color: var(--coco-secondary-a-200)!important;
  }
  &.swal2-warning{
    border-color: #FFAF6A!important;
    color: #FFAF6A!important;
  }
  &.swal2-error{
    border-color: var(--coco-danger-400)!important;
    color: var(--coco-danger-400)!important;
    [class^=swal2-x-mark-line]{
      background-color:var(--coco-danger-400)!important;
      width: 3em!important;
      height: 0.5em!important;
    }
  }
}

.swal2-styled{
  &:focus{
    box-shadow: none;
  }
}

.swal2-loader{
  border-color: $primary transparent $primary transparent;
}

.swal2-timer-progress-bar{
  background-color: rgba($success, 0.4);
}

.swal2-progress-steps {
  .swal2-progress-step{
    background: $primary;
    &.swal2-active-progress-step{
      background: $primary;
      &~.swal2-progress-step, &~.swal2-progress-step-line{
        background: rgba($primary, 0.3);
      }
    }
  }
  .swal2-progress-step-line{
    background: $primary;
  }
}

.swal2-actions.swal2-loading{
  .swal2-styled.swal2-confirm{
    border-left-color: $primary !important;
    border-right-color: $primary !important;
  }
}

.swal2-file, .swal2-input, .swal2-textarea{
  border: 1px solid $input-border-color;
  &:focus{
    box-shadow: none;
    border-color: $input-focus-border-color;
  }
}

.swal2-input{
  height: auto;
  display: block;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
}

.swal2-close{
  font-family: $font-family-base;
  font-weight: $font-weight-light;
  font-size: 28px;
  color: var(--coco-gray-400)!important;
  width: 20px!important;
  height: 20px!important;
  margin: 4px!important;
  padding: 0px!important;
  &:focus{
    box-shadow: none;
  }

  &:hover{
    color: $primary;
  }
}

.swal2-validation-message{
  background-color: transparent;
}