// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

@import 'pages/user/label/annotation/image/base.scss';

.coco-canvas-grid-root {
    position: relative;
}

.coco-canvas-grid-root-single {
    .coco-grid-item-drag-handler {
        display: none;
    }

    .coco-grid-item-fullscreen-handler {
        display: none;
    }

    .coco-grid-item-resize-handler {
        display: none;
    }

    .coco-grid-item-close-button {
        display: none;
    }

    & +.coco-grid-layout-common-setups {
        display: none;
    }
}

.coco-grid-layout-common-setups {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(0, calc($grid-unit-size * 12 - 1px));
    z-index: 1000;
    background: $background-color-2;
    line-height: $grid-unit-size * 3;
    height: calc($grid-unit-size * 3 + 1px);
    padding-bottom: $grid-unit-size;
    padding-right: $grid-unit-size;
    padding-left: $grid-unit-size;
    border-radius: 0 0 4px 4px;
    border-bottom: 1px solid $border-color-1;
    border-right: 1px solid $border-color-1;
    border-left: 1px solid $border-color-1;

    > span {
        margin-right: $grid-unit-size * 2;

        &:last-child {
            margin-right: 0;
        }
    }
}

.coco-canvas-grid-item {
    background-color: rgba(242, 249, 247, 0.7);
    border-radius: 4px;

    &.react-grid-item.cssTransforms {
        transition-property: all;
    }

    &.coco-canvas-grid-fullscreen-item {
        width: 100% !important;
        height: 100% !important;
        padding-right: $grid-unit-size;
        transform: translate(4px, 4px) !important;
        z-index: 1;

        .coco-grid-item-resize-handler.react-resizable-handle,
        .coco-grid-item-drag-handler {
            visibility: hidden;
        }
    }

    .coco-grid-item-drag-handler,
    .coco-grid-item-fullscreen-handler,
    .coco-grid-item-close-button {
        position: absolute;
        top: $grid-unit-size;
        z-index: 1000;
        font-size: 16px;
        background: $header-color;
        border-radius: 2px;
        opacity: 0.6;
        transition: all 200ms;

        &:hover {
            opacity: 0.9;
        }

        &.coco-grid-item-drag-handler {
            left: $grid-unit-size * 4;
            cursor: move;
        }

        &.coco-grid-item-fullscreen-handler {
            left: $grid-unit-size;
        }

        &.coco-grid-item-close-button {
            right: $grid-unit-size;
        }
    }

    .coco-grid-item-resize-handler.react-resizable-handle {
        bottom: -3px;
        right: -3px;
        cursor: se-resize;

        &::after {
            bottom: 0;
            right: 0;
            width: 9px;
            height: 10px;
            border-right: 2px solid rgba(0, 0, 0, 1);
            border-bottom: 2px solid rgba(0, 0, 0, 1);
        }
    }
}
