.review-details {
  background-color: var(--coco-default-white);
  border-bottom: 1px solid var(--coco-gray-100);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 24px;
  font-size: 16px;
  font-weight: 500;
  color: var(--coco-gray-700);
  .title {
    font-weight: 600;
    display: flex;
    align-items: center;
    line-height: 20px;
    gap: 6px;
  }
  .subtitle{
    font-size: 12px !important;
    color: var(--coco-gray-400);
  }
  .content .form-label{
    margin-bottom: 12px;
  }
}
