.content-container.clsf-smry{
  ::part(scroll){
    padding: 8px 0;
  }
}

.questions-side-bar{
  position: fixed;
  top: 70px;
  right: 0;
  width: 588px;
  padding-right: 0 !important;
  padding-left: 0 !important;
  background: var(--coco-default-white, #FFF);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.review-result{
  display: flex;
  gap: 8px;
  flex-direction: column;
  .result-title {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.guide-popover{
  .popover {
    background-color: rgba(0, 0, 0, 0.7);
    border: 0;
    color: var(--coco-default-white);
    padding: 12px 16px;
    font-weight: 600;
    line-height: 18px;
  }

  .popover-arrow {
    &::after, &::before{
      border-top-color: rgba(0, 0, 0, 0.7) !important;
    }
  }
}


.summary-review-div{
  &:not(.read-only){
    .rta-text-container{
      cursor: url("/assets/images/dragCursor-24.png"), auto;
    }
  }
  &.read-only{
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
}

.summary-review-answer{
  padding-right: 12px;
  padding-left: 12px;

  .summary-review-answer-detail{
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    align-items: center;

    .tagging-sentence{
      flex-shrink: 1;
      flex-grow: 0;
    }
    .tagging-answer{
      flex-shrink: 1;
      flex-grow: 1;
    }
  }

  .summary-review-answer-detail:first-child{
    margin-top: 12px;
  }

  .summary-review-answer-detail:not(:last-of-type){
    margin-bottom: 8px;
  }
}

.summary-review-result-list {

  .summary-review-answer-item {
    display: flex;
    align-items: start;
    margin-bottom: 12px;

    .summary-review-answer-result {
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
      align-items: center;

      .tagging-sentence {
        flex-shrink: 1;
        flex-grow: 0;
        .badge{
          white-space: normal;
          text-align: start;
        }
      }

      .tagging-detail {
        flex-shrink: 1;
        flex-grow: 1;
      }
    }
  }
}
