.basic-info {
  hr{
    background-color: var(--coco-gray-100);
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
.info-section {
  font-size: 16px;
  line-height: 19px;
  color: var(--coco-gray-700);
  font-weight: 500;
  .title {
    font-weight: 600;
    line-height: 20px;
    .badge {
      padding: 4px !important;
      width: 24px;
      height: 24px;
      font-size: 16px;
    }
  }
  .line {
    display: flex;
    gap: 8px;
    flex-basis: 110px;
    word-wrap: break-word;
    word-break: break-all;
    padding: 5px 0;
  }
}

#file-history .acitivity-manager-setting .acitivity-item:before{
  left: 16px;
}

.history-item{
  color: var(--coco-gray-700);
  padding-bottom: 24px;
  .subtitle{
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }
  .title {
    display: flex;
    font-size: 14px;
    font-weight: 600;
  }
  .message{
    font-size: 14px;
    font-weight: 400;
  }
  .date{
    font-size: 14px;
    font-weight: 300;
  }
}

.event-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1;
  position: relative;

  font-weight: 600;
  font-size: 16px;
  border-radius: 50%;

  background-color: #FCE4DF;
  color: #FF5A5A;

  text-align: center;
  width: 32px;
  height: 32px;
}

#jsonPreviewModal .form-control
,#targetFileTextModal .form-control{
  background-color: var(--coco-default-white);
  color: var(--coco-gray-700) !important;
  &::placeholder{
    background-color: var(--coco-default-white);
  }
}

.coco-objects-sidebar-stage-list{
  .accordion-header{
    &.open{
      background: var(--coco-primary-50);
      box-shadow: 6px 0px 0px 0px var(--coco-primary-400) inset;
    }
  }
}