//
// Login.scss
//

.auth-page-wrapper{
  .auth-page-content{
    //padding-bottom: $footer-height;
    position: relative;
    z-index: 2;
    width: 100%;
  }
  .footer{
    left: 0;
    background-color: transparent;
    color: $body-color;
  }
}