.data-list-bar {
  width: 298px !important;
  padding: 12px 5px !important;
  color: var(--coco-gray-700);
  hr {
    background-color: var(--coco-gray-100);
    margin-bottom: 0;
  }
  #search-input-file{
    margin: 4px 12px 0 12px;
  }
  .search-condition{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    margin-top: 12px;
  }

  .filter-button-wrapper {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .dropdown {
    .title {
      font-weight: 400;
      color: var(--coco-gray-700);
    }

    .dropdown-header {
      font-weight: 600;
      font-size: 16px;
      color: var(--coco-gray-700);
      padding: 0;
    }

    .dropdown-divider {
      border-color: var(--coco-gray-400);
    }

    .filter-contents {
      .dropdown-header {
        font-weight: 500;
        font-size: 14px;
        color: var(--coco-gray-400);
        padding: 0;
      }
      .dropdown-divider {
        border-color: var(--coco-gray-50);
      }
    }

    .dropdown-item {
      color: var(--coco-gray-700);

      .form-check{
        font-weight: 500;
        font-size: 16px;
        margin: 0;
        .form-check-label{
          margin-bottom: 0;
          line-height: 19px;
        }
      }
    }
    .filter-button-title{
      //max-width: 208px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .vl {
      border-left: 1px solid var(--coco-primary-200);
      height: 100%;
    }


  }
}

.filter-button{
  max-width: 264px;
  height: 22px;
  padding: 4px 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  gap: 4px;
  i {
    font-size: 14px;
  }
}

.filter-dropdown{
  .dropdown-menu{
    padding: 0;
  }

  .filter-container {
    padding: 16px;

    .dropdown-item{
      padding: 0;
    }

    &.text-container {
      width: 250px;
    }
  }
}

.data-list{
  .data-card {
    border-radius: 4px;
    height: 48px;
    font-size: 16px;
    line-height: 20px;
    padding: 14px 12px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    &.selected, &:hover {
      background-color: var(--coco-primary-50);
    }
  }
}
