
.skipPopover{
  .popover{
    z-index: 800;
    width: 418px;
    max-width: 418px;
    border: 2px solid var(--coco-primary-400);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.17);
  }

  .popover-arrow::after {
    bottom: 2px !important;
    border-color: var(--coco-default-white) transparent transparent !important;
    border-width: 8px;
    border-style: solid;
  }
  .popover-arrow::before {
    border-color: var(--coco-primary-400) transparent transparent !important;
    border-width: 10px;
    border-style: solid;
    color: transparent;
  }
}
.skipPopoverContent{
  margin: 24px 24px 18px 24px;
  .title{
    line-height: 24px;
    margin-bottom: 16px;
    i {
      font-size: 20px;
      color: var(--coco-danger-400);
      margin-right: 10px;
    }
    .popover-title{
      font-size: 20px;
      font-weight: 600;
      margin-right: 6px;
    }
    .popover-subtitle{
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
  }
  .content{
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    .form-label {
      margin-bottom: 12px;
    }
  }
  .skip-popover-input-text-container{
    margin-left: 25.6px;
    margin-top: -8px;
    .form-control{
      padding: 8px 4px !important;
    }
  }
  .skip-popover-footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    margin-top: 16px;
    margin-left: auto;
    button{
      width: 84px;
    }
  }
}

.submit {
  border: 0;
}
