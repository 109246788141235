.label-event-list-container{
  .card-header:hover{
    background-color: var(--coco-primary-50);
  }
  .error> .card-header{
    background-color: var(--coco-danger-50);
    :hover{
      background-color: var(--coco-danger-a-50);
    }
  }

  .selected{
    > .card-header{
      background-color: var(--coco-primary-50);
    }

    &.error>.card-header{
      background-color: var(--coco-danger-a-50);
    }
  }
}
