//
// _form-input-spin.scss
//


[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  height: auto;
}

.input-step {
  border: 1px solid $input-border-color;
  display: inline-flex;
  overflow: visible;
  height: 37.5px;
  @include border-radius($input-border-radius, 0);
  background: $input-bg;
  padding: 4px;

  input {
    width: 4em;
    height: 100%;
    text-align: center;
    border: 0;
    background: transparent;
    color: $input-color;
    border-radius: $input-border-radius;

    &:focus-visible {
      outline: 0;
    }
  }

  button {
    width: 1.4em;
    font-weight: 300;
    height: 100%;
    line-height: 0.1em;
    font-size: 1.4em;
    padding: 0.2em !important;
    background: var(--#{$variable-prefix}light);
    color: var(--#{$variable-prefix}body-color);
    border: none;
    border-radius: $btn-border-radius;
  }

  &.light {
    background: var(--#{$variable-prefix}light);

    button {
      background-color: $input-bg;
    }
  }

  &.light-input {
    background: var(--#{$variable-prefix}light);

    input {
      background-color: $input-bg;
    }
  }

  &.full-width {
    display: flex;
    width: 100%;

    button {
      flex-shrink: 0;
    }

    input {
      flex-grow: 1;
    }
  }
}

@each $color,
$value in $theme-colors {
  .input-step {
    &.step-#{$color} {
      button {
        background-color: $value;
        color: $white;
      }
    }
  }
}
