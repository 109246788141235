.shortcutsBody {
  border-radius: 7px;

  display: flex;
  flex-direction: row;
  //align-items: flex-start;
  align-items: center;
  width: 1176px;
  //height: 400px;

  .shortcutCol {
    flex-grow: 1;

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    align-self: stretch;
  }

  .shortcuts-section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding: 20px 16px;

    color: var(--coco-default-white);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    .title{
      font-weight: 600;
    }
  }

  .shortcuts-info{
    //width: 100%;
    width:  260px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //gap: 16px;
    padding: 0;
  }

  .shortcuts-info-keys{
    display: flex;
    gap: 8px;
  }
}

.shortcutsPopover {
  .popover{
    max-width: 1178px !important;
    background-color: rgba(0, 0, 0, 0.9);
    border: 0;
  }
  .popover-arrow {
    &::after, &::before{
      border-bottom-color: rgba(0, 0, 0, 0.7) !important;
    }
  }
}

.outline-box-white {
  color: var(--coco-default-white);
  padding: 2px;
  border: 1px solid var(--coco-default-white);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  &.one-letter {
   width: 20px;
  }
}

.col-broken-line-white {
  height: 380px;
  width: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px dashed var(--coco-default-white);
}
