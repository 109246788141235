// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

$grid-unit-size: 8px;

$header-height: $grid-unit-size * 6;

$layout-lg-grid-size: $grid-unit-size * 2;
$layout-sm-grid-color: rgba(0, 0, 0, 0.15);
$layout-lg-grid-color: rgba(0, 0, 0, 0.15);

$header-color: #d8d8d8;
$text-color: #303030;
$text-color-secondary: rgba(0, 0, 0, 0.45);
$hover-menu-color: rgba(24, 144, 255, 0.05);
$completed-progress-color: #61c200;
$inprogress-progress-color: #1890ff;
$pending-progress-color: #c1c1c1;
$border-color-1: #c3c3c3;
$border-color-2: rgb(240, 240, 240);
$border-color-3: #242424;
$border-color-hover: #40a9ff;
$background-color-1: white;
$background-color-2: #f1f1f1;
$notification-background-color-1: #d9ecff;
$notification-border-color-1: #1890ff;
$transparent-color: rgba(0, 0, 0, 0);
$player-slider-color: #979797;
$player-buttons-color: #242424;
$danger-icon-color: #ff4136;
$ok-icon-color: #61c200;
$info-icon-color: #0074d9;
$objects-bar-tabs-color: #bebebe;
$objects-bar-icons-color: #242424; // #6e6e6e
$active-label-background-color: #d8ecff;
$object-item-border-color: rgba(0, 0, 0, 0.7);
$slider-color: #1890ff;

$box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);

$monospaced-fonts-stack: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono,
    Courier New, monospace;
