//@import "../../../assets/scss/config/custom.scss";

#clcl{
    background-color: var(--coco-bg-main);
    .main-content {
        min-height:100% !important;
        margin-left: 70px;
    }

    .page-content{
        padding: 70px 0 0 0;
    }

    .coco-standard-workspace {
        height: calc(100vh - 70px);
    }

    .navbar-header {
        padding-left: 1.5rem;
    }
    .canvas-header {
        border-bottom: 0;
    }
    .navbar-brand-box {
        padding: 0;
        top: 0;
    }

    .navbar-menu {
        top: calc(70px);
        border-right: var(--vz-vertical-menu-bg);
        border-radius: 5px;
        padding: 10px 0;
        z-index: 1;
        width: 60px;
        background: var(--coco-default-white);
    }

    #page-topbar {
        left: 0 !important;
        -webkit-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
        box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    }

    .navbar-brand-box, .navbar-brand-box {
        background-color: transparent !important;
        position: relative;
        width: auto;
        text-align: left;
    }

    .label-object-menu-div{
        height: calc(100vh - 70px);
        min-height: 800px;
        margin-bottom: 0;
        min-width: 360px;
    }

    .coco-controls-side-bar {
        display: flex;
        flex-direction: column;
        align-items: center;
        //padding: 0;
        div{
            display: flex;
        }
    }

    .coco-active-canvas-control {
        background-color: var(--coco-primary-400) !important;
        i {
            background-color: var(--coco-default-white) !important;
        }
    }

    .app-menu .nav-item{
        display: flex!important;
        justify-content: center;
        padding-top: 0.12rem!important;
        padding-bottom: 0.12rem!important;
    }

    .app-menu .nav-item button{
        padding-top: 0!important;
        padding-bottom: 0!important;
        padding-right: 0.25rem!important;
        padding-left: 0.25rem!important;
    }

    .menu-title i{
        color: var(--coco-gray-700);
        text-align: center;
        display: block;
    }
    .choices__input.choices__input--cloned{
        width: auto !important;
    }

    .btn-coco-ghost {
        color: var(--coco-gray-700);
        border-color: transparent;
        background-color: transparent;
    }

    .btn-coco-ghost:hover {
        color: var(--coco-primary-400);
        border-color: transparent;
        background-color: var(--coco-primary-50);
    }

    .btn-coco-ghost-1:disabled {
        color: var(--coco-primary-100);

    }

    .form-switch-dark .form-check-input:checked{
        border-color: #ffffff;
    }

    .app-menu .nav-item .selected{
        background-color: var(--coco-primary-400) !important;
        color: #ffffff;
    }

    .app-menu .nav-item .selected .i-coco-20{
        background-color: #FFFFFF;
    }

    .nav-tabs>li>a{
        font-weight: 600;
    }

    .popover .card-header .i-coco-20{
        background-color: #FFFFFF;
    }


    .labelToolPopover .popover, .labelToolPopover .popover-header {
        background-color: rgba(6, 6, 6, 0.9);
        max-width: max-content !important;
    }

    .labelToolPopover .bs-popover-end {
        border-color: rgba(6, 6, 6, 0.9);
    }

    .labelToolPopover .popover-arrow::after {
        border-right-color: rgba(6, 6, 6, 0.9) !important;
    }

    .labelToolPopover .bs-popover-bottom, .popover-arrow::after,
    .labelToolPopover .bs-popover-bottom, .popover-arrow::before{
        border-bottom-color: rgba(6, 6, 6, 0.9);
    }

    .enumListPopover .btn:disabled {
        background-color: var(--coco-gray-50);
        color: var(--coco-gray-400);
        border: 1px transparent;
        height: 25px;
        line-height: 2px;
    }

    .enumListPopover .btn[data-checked]:disabled {
        background-color: var(--coco-gray-100);
        color: var(--coco-gray-700);
    }


    .instance-side-bar {
        position: fixed;
        top: 70px;
        right: 0;
        width: 360px;
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .instance-side-bar, .questions-side-bar{
        .card .card-header{
            padding: 0;
            border: 0;
        }
        .card {
            margin-bottom: 0;
            box-shadow: none;
        }
    }

    .instance-side-bar .detailShapeItem {
        width: 100%;
        height: 50px;
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;

    }
    .instanceItem .regionClassName, .classesItem .className{
        width: fit-content;
    }
    .instanceItem .card-body, .classesItem .card-body {
        border-bottom: 1px solid var(--coco-gray-100);
    }
    .instanceItem .selected, .classesItem .selected{
        background-color: var(--coco-primary-50-op-70);
        position: relative;
    }
    .instanceItem .error, .classesItem .error{
        background-color: var(--coco-danger-50);
        position: relative;
    }
    .detailShapeItem {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: var(--coco-gray-700);
    }
    .reviewNext{
        color: var(--coco-primary-400);
    }
    .reviewNext:disabled{
        color: var(--coco-gray-400);
    }

    .issueItem > .card-header{
        cursor: pointer;
    }

    .cursorTarget{
        cursor: url("/assets/images/shapeCursor.png"), auto;
    }

    div[type="button"][disabled="disabled"] {
        pointer-events: none;
        opacity: .65;
        cursor: default;
    }
    .mn-content-container{
        position: relative;
        left: 298px;
        height: calc(100vh - 70px);
        width: calc(100vw - (298px + 360px));
        .canvas-grid-root{
            height: calc(100vh - 70px);
        }
        &.clsfc-smry{
            left: 0;
            width: 100%;
        }
    }
    .content-container{
        position: relative;
        left: 60px;
        height: calc(100vh - 70px);
        width: calc(100vw - (60px + 360px));
        //width: 100%;
        .canvas-grid-root{
            height: calc(100vh - 70px);
        }
        &.clsfc-smry {
            left: 0;
            width: calc(100vw - (588px));
        }
    }
    .svgContent, .guideContent, .canvasContent, .canvasCursor {
        position: absolute;
        z-index: 1;
        /* outline: 1px solid black; */
        transform-origin: top left;
        min-width: 1030px;
        min-height: 800px;
        width: calc(100% - 2rem);
        height: calc(100vh - 70px - 2rem);
    }


    #canvasCursor {
        left: 1rem!important;
        top: 1rem!important;
        pointer-events: none!important;
    }

    .coconut_desktop_shape .coconut_desktop_interact_intermediate_shape {
        stroke-opacity: 1;
        cursor: pointer;
    }

    #label{
        height: calc(100vh - 70px);
        top: 70px;
        bottom: 1.5rem;
        padding: 0;
        border-right: var(--vz-vertical-menu-bg);
        border-radius: 5px;
        padding: 0;
        z-index: 1;
        min-height: 800px;
        min-width: 1030px;
        font-weight: 500;
        /* height: 700px; */
    }

    .task-title{
        color: rgba(0, 0, 0, 0.6);
    }

    .nav-tabs .badge{
        position: relative;
        right: -1px;
        background: var(--coco-danger-400);
        z-index: 10;
        width: 20px;
        height: 20px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    .canvas-percent-div{
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1000;
    }

    .canvas-percent-div span{
        text-align: right;
        width:40px;
    }
    .logo .logo-lg {
        //width: 230px;
        font-weight: 900 !important;
        font-size: 36px;
        line-height: normal;
    }

    .canvas-header .subtitle {
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        /*margin-bottom: 5px;*/
    }
    .header-item{
        #faq {
            cursor: pointer;
            font-size: 16px;
            width: 50px;
            height: 28px;
            line-height: 20px;
        }
    }
}

.labelToolPopover{
    .popover, .popover-header{
        background-color: rgba(6, 6, 6, 0.9);
        max-width: max-content !important;
    }
    .bs-popover-end {
        border-color: rgba(6, 6, 6, 0.9);
    }
    .popover-arrow::after {
        border-right-color: rgba(6, 6, 6, 0.9) !important;
    }
    .bs-popover-bottom{
        border-bottom-color: rgba(6, 6, 6, 0.9);
    }
}
