/*Custom controls*/
.reactflow-wrapper .react-flow__panel.left{
    left: 17px;
}
.reactflow-wrapper .react-flow__panel.top{
    top: 65px;
}

.workflow-canvas-div{
    position: absolute;
    z-index: 999;
    top: 24px;
    left: 32px;
    font-size: 20px;
    font-weight: 600;
    color: var(--coco-gray-200);
    display: flex;
    align-items: center;
    line-height: 24px;
}
.workflow-canvas-div i{
    margin-right:16px;
    color: var(--coco-gray-400);
}

/*
Custom offcnavas
 */
.offcanvas-body{
    padding: 24px 24px;
}
.offcanvas-header{
    padding: 16px 24px;
    background-color: var(--coco-primary-400);
}
.offcanvas-title{
    color: var(--coco-default-white);
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}
.offcanvas-title .form-control{
    color: var(--coco-default-white)!important;
}
.offcanvas-end{
    width: 864px;
}

.offcanvas-footer{
    height: 58px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px 24px;
    border-top: 1px solid var(--coco-gray-100);
    position: absolute;
    bottom: 0;
}

.offcanvas{
    filter: drop-shadow(-2px 4px 4px rgba(0, 0, 0, 0.15));
}

/**
Custom module lib ui
 */
.module-type{
    margin-top: 20px;
    color: var(--coco-gray-700);
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.module-type i{
    margin-right:8px;
}

.module-type:first-child{
    margin-top: 0px;
}

.module-div{
    display: flex;
    align-items: center;
}

.module-item{
    width: 150px;
    height: 130px;
    padding: 12px 15px;
    background: var(--coco-default-white);
    outline: 1px solid var(--coco-gray-100);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    justify-content: center;
}

.module-div .module-item:not(:first-child){
    margin-left: 16px;
}

.module-item .avatar{
    width: 54px;
    height: 54px;
    font-size: 28px;
}

.module-item .module-icon{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.module-item .module-label{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--coco-gray-700);
    text-align: center;
    maring-bottom:4px;
}

.module-item .module-info{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--coco-gray-400);
    text-align: center;
}

/**
Custom offcanvas module
 */
.module-title{
    margin-top: 25px;
    color: var(--coco-gray-700);
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}
.module-title i{
    margin-right:10.5px;
}

.module-title:first-child{
    margin-top: 0px;
}

.form-label{
    margin-bottom: 8px;
    color: var(--coco-gray-700);
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}

.coco-hr{
    margin: 25px 0;
    border-top: 1px solid var(--coco-gray-100);
}

.dndflow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
    padding-right: 0px;
    padding-left: 0px;
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
}

/*.dndflow .react-flow__node:hover,
*/
.dndflow .react-flow__node.selectable.selected,
.dndflow .react-flow__node.selectable.selected:focus
{
    outline: 2px solid var(--coco-primary-400);
    z-index: 8!important
}

.dndflow .react-flow__node.selectable:focus
{
    outline: 0px solid var(--coco-primary-400);
    z-index: 0!important
}


/*.dndflow .react-flow__node:hover .react-flow__handle.source,*/
/*.dndflow .react-flow__node:focus .react-flow__handle.source,*/
.dndflow .react-flow__node.selected .react-flow__handle.source,
.dndflow .react-flow__node.selected:focus .react-flow__handle.source{
    visibility: visible;
}

/*.dndflow .react-flow__node.selected .react-flow__handle.source,*/
/*.dndflow .react-flow__node:focus .react-flow__handle.source{*/
/*    visibility: visible;*/
/*}*/

.react-flow__handle{
    visibility: hidden;
    width: 10px;
    height: 10px;
}
.react-flow__handle-top{
    top: -6px;
}
.react-flow__handle-right{
    right: -6px;
}
.react-flow__handle-bottom{
    bottom: -6px;
}
.react-flow__handle-left{
    left: -6px;
}

.react-flow__handle-valid {
    background: #55dd99;
}

.react-flow__handle.source{
    background-color: var(--coco-gray-200);
    border-color: var(--coco-gray-300);
}

.react-flow__handle.target{
    background-color: var(--coco-gray-50);
    border-color: var(--coco-gray-300);
}


@media screen and (min-width: 768px) {
    .dndflow {
        flex-direction: row;
    }
}

/**
Common Node Custom
 */
.react-flow__node{
    width: 290px;
    height: 273px;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}
.react-flow__node .node_info{
    width: 250px;
    /*height: 88px;*/
    color: var(--coco-gray-700);
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 10px;
}
.react-flow__node .node_info label{
    font-weight: 500;
    width: 52px;
    margin-right: 10px;
}


/**
 Dateset Node Custom
 */
.react-flow__node-dataset .image-info div{
    border-radius: 4px 0px 0px 4px;
    height: 55px;
    width: 82px;
}
.react-flow__node-dataset .image-info .image-data:not(:first-of-type) {
    margin-left: 2px;
}

.react-flow__node-dataset .image-info .default-data{
    background-color: var(--coco-gray-50);
    color: var(--coco-gray-200);
    font-size: 12px;
    font-weight: 400;
}

/**
 Label Node Custom
 */

/**
 Review Node Custom
 */

/**
 Finish Node Custom
 */
.react-flow__node-managerReview{
    width: 290px;
    height: 254px;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}

.react-flow__node-finish,
.react-flow__node-unabled{
    width: 290px;
    height: 82px;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}

.workflow_dashboard_flow .react-flow__node-finish{
    width: 290px;
    height: 173px;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}

.react-flow__node-sdq4ai,
.react-flow__node-autoLabel
{
    width: 290px;
    height: 158px;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}
/*


.workflow_dashboard_flow .react-flow__node-managerReview{
    height: 254px!important;
}

.workflow_dashboard_flow .react-flow__node-restore,
.workflow_dashboard_flow .react-flow__node-subUnabled{
    top: 45px!important;
}
*/

    /**
    Sub Node Custom
     */
.react-flow__node-accept,
.react-flow__node-reject,
.react-flow__node-approval,
.react-flow__node-ambiguity,
.react-flow__node-labeled,
.react-flow__node-skip,
.react-flow__node-restore,
.react-flow__node-subUnabled,
.react-flow__node-stateChange,
.react-flow__node-submit,
.react-flow__node-updateSubmit{
    width:250px;
    height: 48px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: var(--coco-gray-700);
    padding: 14px;
    text-align: center;
    border-radius: 4px;
    outline: 1px solid var(--coco-gray-100);
    box-shadow: none;
}

.shape__value-container [class*=DummyInput],.shape__value-container [class*=dummyInput]{
    height: 0px;
}

.projectClassListDiv .list-group-item{
    border-radius: 0px;
}
.projectClassListDiv .list-group-item:hover{
    background: var(--coco-primary-50);
}

.projectClassListDiv .list-group-item.selected{
    background: var(--coco-primary-50);
    box-shadow: 6px 0px 0px 0px var(--coco-primary-400) inset;
}

.projectClassListDiv .list-group-item.validation-error-sub{
    background: var(--coco-danger-50);
}


.projectClassListDiv .list-group-item.validation-error-sub:hover{
    background: var(--coco-danger-a-50);
}

.projectClassListDiv .list-group-item.validation-error-sub.selected{
    background: var(--coco-danger-a-50);
    box-shadow: 6px 0px 0px 0px var(--coco-danger-400) inset;
}

.projectAttributeTargetDiv .projectAttributeItem:focus-within
,.projectAttributeTargetDiv .projectAttributeItem[draggable=true]{
    border: 2px solid var(--coco-primary-400);
}

.projectAttributeTargetDiv .projectAttributeItem{
    border-radius: 7px!important;
}

.projectAttributeTargetDiv .projectAttributeItem .card-header{
    border-radius: 7px 7px 0 0!important;
}

.metaInfoDiv{
    padding: 16px;
    font-size: 16px;
    line-height: 20px;
    color: var(--coco-gray-700);
    background-color: var(--coco-gray-50);
    border-radius: 7px;
    border: 1px dashed var(--coco-gray-100);
    margin-top: 10px;
}

.metaInfoDiv label{
    margin: 0 12px 0 0 !important;
    font-weight: 500;
    width: 60px;
}


#local-upload-fileList .list-group-item-action {
    padding: 12px 8px;
}

#local-upload-fileList .list-group-item-action:hover {
    background-color: var(--coco-primary-50-op-70);
}

#local-upload-fileList .list-group-item-action .btn-coco-xs-1{
    opacity: 0;
}

#local-upload-fileList .list-group-item-action:hover .btn-coco-xs-1 {
    opacity: 1;
}

#targetFileModal{
    max-width:804px;
}
#targetFileModal .modal-content{
    border-width:0px;
}
#targetFileAudioModal .modal-content{
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.testDropzoneDiv .list-group-item{
    border-radius: 0px;
}
.testDropzoneDiv .list-group-item:hover{
    background: var(--coco-primary-50);
}

.testDropzoneDiv .list-group-item.selected{
    background: var(--coco-primary-50);
    box-shadow: 6px 0px 0px 0px var(--coco-primary-400) inset;
}

.testDropzoneDiv .list-group-item.hasError{
    background: var(--coco-danger-50);
}

.testDropzoneDiv .list-group-item.hasError:hover{
    background: var(--coco-danger-a-50);
}

.testDropzoneDiv .list-group-item.hasError.selected{
    background: var(--coco-danger-a-50);
    box-shadow: 6px 0px 0px 0px var(--coco-danger-400) inset;
}
